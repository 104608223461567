import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import SendIcon from '@mui/icons-material/Send';

const validateEmail = (email) => {
  email = email.toLowerCase();
  const emailRegex = new RegExp(
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
  );
  if (!emailRegex.test(email)) {
    return false;
  }
  return true;
};

const submitContact = (
  name,
  email,
  message,
  setError,
  setLoading,
  setSuccess,
  clearForm
) => {
  if (!name) {
    setError({
      message: 'Please enter your name',
      field: 'name',
    });
    return;
  } else if (!email || !validateEmail(email)) {
    setError({
      message: 'Must be a valid email address',
      field: 'email',
    });
    return;
  } else if (!message) {
    setError({
      message: 'Please enter your message',
      field: 'message',
    });
    return;
  }
  setLoading(true);
  const today = new Date();
  const time = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const data = { name, email, message, time };

  axios
    .post(`${process.env.GATSBY_MONEYCL_SERVICE_URL}/submit`, data)
    .then(() => {
      clearForm();
      setSuccess(true);
      setLoading(false);
    })
    .catch(() => {
      setError({
        message:
          'There was an issue submitting your message, please try again later.',
      });
      setLoading(false);
    });
};

const Contact = () => {
  const [loading, setLoading] = useState(false),
    [error, setError] = useState({}),
    [success, setSuccess] = useState(false),
    [name, setName] = useState(''),
    [email, setEmail] = useState(''),
    [message, setMessage] = useState(''),
    clearForm = () => {
      setName('');
      setEmail('');
      setMessage('');
    };

  return (
    <section>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact - Moneycl App</title>
        <link rel="canonical" href="https://moneycl.app/contact" />
      </Helmet>
      <Paper
        elevation={5}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 20,
          maxWidth: 500,
          width: '70%',
          padding: '25px 40px',
          margin: 'auto',
          marginTop: 15,
          borderRadius: 15,
        }}
      >
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 30,
            margin: 0,
          }}
        >
          Contact us
        </h1>{' '}
        {!success && (
          <>
            <span style={{ textAlign: 'center' }}>
              Feel free to contact us with any feedback you may have.
            </span>
            <span style={{ textAlign: 'center' }}>
              If your app has crashed or you believe it has errored, please do
              your best to describe the events that lead to the issue.
            </span>
            <FormControl error={error.field === 'name'} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
              <OutlinedInput
                id="outlined-adornment-name"
                onChange={(e) => setName(e.target.value)}
                label="Name"
                aria-describedby="name-error-text"
              />
              {error.field === 'name' && (
                <FormHelperText id="name-error-text">
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl error={error.field === 'email'} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                aria-describedby="email-error-text"
              />
              {error.field === 'email' && (
                <FormHelperText id="email-error-text">
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl error={error.field === 'message'} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-message">
                Message
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-message"
                onChange={(e) => setMessage(e.target.value)}
                label="Message"
                multiline
                minRows={4}
                aria-describedby="message-error-text"
              />
              {error.field === 'message' && (
                <FormHelperText id="message-error-text">
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
            <LoadingButton
              variant="contained"
              aria-label="submit contact request"
              loading={loading}
              loadingPosition="end"
              endIcon={<SendIcon />}
              onClick={() =>
                submitContact(
                  name,
                  email,
                  message,
                  setError,
                  setLoading,
                  setSuccess,
                  clearForm
                )
              }
            >
              Submit
            </LoadingButton>
          </>
        )}
        {success && (
          <>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center', color: 'green' }}>
              Successfully sent your message!
            </span>
            <Button variant="contained" href="/">
              Go home
            </Button>
          </>
        )}
      </Paper>
    </section>
  );
};

export default Contact;
